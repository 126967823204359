<template>
  <div style="margin-top: 0 !important">
    <CreateDialog />
  </div>
</template>

<script>
import CreateDialog from "@/components/common/CreateDialog.vue";
export default {
  components: {
    CreateDialog,
  },
};
</script>

<style></style>
